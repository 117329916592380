<template>
  <base-layout-2
    :noPageHeader="false"
    :toolbarClass="'class-3'"
    :headerClass="'fixed-home'"
    :pageDefaultBackLink="'/home'"
    :contentColor="'light'"
    :backButtonClass="'btn-1 signal-page-btn signal-page-back-btn dark'"
    :contentScrollEvents="true"
    :onContentScroll="contentScroll"
    :ref="layout"
    :appDownloadBtn="false"
    @content="setIonContent"
  >
    <template v-slot:actions-end>
      <ion-button
        :class="'btn-1 signal-page-btn ' + toolbarTheme"
        id="settings-btn"
        router-link="/settings"
        v-if="!browser"
      >
        <ion-icon slot="icon-only" :icon="settingsSharp"></ion-icon>
      </ion-button>
    </template>

    <template v-slot:ion-content>
      <div class="page-container ion-padding" id="page-container">
        <div class="header" id="header">
          <div class="logo">
            <ion-thumbnail>
              <img
                v-if="ready"
                :src="server + '/storage/tokens/' + signal.data.logo"
              />
            </ion-thumbnail>
          </div>
          <div class="item-1">
            <ion-text class="market">
              <p>
                <strong>
                  <template v-if="ready">
                    {{ signal.data.market }}
                    <span v-if="signal.data.type != 'spots'" :class="signal.data.leverage_type == 'long' ? 'success-text' : 'danger-text'">{{ leverage }}</span>
                  </template>
                  <ion-skeleton-text
                    v-else
                    style="width: 60px; display: inline-block"
                  ></ion-skeleton-text>
                </strong>
              </p>
            </ion-text>

            <ion-text class="other">
              <p>
                <template v-if="ready">
                  <span>{{ $filters.time_lll(signal.data.created_at) }} </span>
                </template>
                <!-- <strong :class="'risk ' + (signal.data.leverage_type == 'long' ? 'success' : 'danger')">
                  <template v-if="ready">
                    {{ leverage }}
                  </template>
                  <ion-skeleton-text
                    v-else
                    style="width: 150px; display: inline-block"
                  ></ion-skeleton-text>
                </strong> -->
              </p>
              <p id="price-action">
                <template v-if="ready">
                  <span class="buy" id="buy"
                    >{{ lang.entry ?? "Entry" }} ~ {{ signal.data.buy }}</span
                  >
                  <span class="stop"
                    >{{ lang.stop ?? "Stop" }} ~
                    {{ signal.data.stoploss }}</span
                  >
                </template>
                <ion-skeleton-text
                  v-else
                  style="width: 100px; display: inline-block"
                ></ion-skeleton-text>
              </p>
            </ion-text>
          </div>

          <div class="item-2">
            <div :class="{ left: true, side: true, hidden: !ready }">
              <template v-for="(target, key) in targets" :key="key">
                <div
                  :class="{
                    target: true,
                    'item-value': true,
                    achieved: target.achieved,
                  }"
                >
                  {{ lang?.["target_" + (key + 1)] ?? "Target " + (key + 1) }}
                  <ion-icon
                    :icon="checkmarkSharp"
                    v-if="target.achieved"
                  ></ion-icon>
                </div>
              </template>
            </div>
            <div :class="{ right: true, side: true, hidden: !ready }">
              <template v-for="(target, key) in targets" :key="key">
                <div
                  :class="{
                    'target-value': true,
                    'item-value': true,
                    achieved: target.achieved,
                  }"
                >
                  {{ target.value + " (" + target.percent + "%)" }}
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="content" id="content">
          <div
            id="chartWrap"
            :style="'height:' + chartHeight + 'px'"
            :class="{ 'chart-ready': chartReady }"
          >
            <div
              id="chart"
              :class="{ 'chart-ready': chartReady }"
              v-if="ready"
            ></div>
            <ion-icon :icon="analyticsOutline"></ion-icon>
          </div>

          <div id="tech_analysis_wrap">
            <div
              id="tech_analysis"
              :class="{ 'tech-ready': techAnalysisReady }"
              ref="tech_analysis"
              v-if="ready"
            ></div>
            <ion-icon :icon="speedometerOutline"></ion-icon>
          </div>
        </div>

        <ion-fab
          :class="{
            'signal-ion-fab': true,
            'watch-signal': true,
            watched: watched,
          }"
          vertical="bottom"
          horizontal="end"
          slot="fixed"
          @click="watchSignal()"
          v-if="!signal.data.no_watch_button"
          id="watch-signal-btn"
        >
          <ion-fab-button class="ion-fab-button">
            <ion-icon :icon="watched ? heart : heartOutline"></ion-icon>
          </ion-fab-button>
        </ion-fab>
      </div>
    </template>
  </base-layout-2>
</template>


<script>
import {
  IonButton,
  IonIcon,
  IonText,
  IonSkeletonText,
  IonThumbnail,
  alertController,
  IonFab,
  IonFabButton,
} from "@ionic/vue";
import {
  settingsSharp,
  speedometerOutline,
  analyticsOutline,
  helpCircleSharp,
  heartOutline,
  heart,
  checkmarkSharp,
} from "ionicons/icons";
import BaseLayout2 from "../layouts/Base2";

export default {
  inject: ["store"],
  data() {
    return {
      settingsSharp,
      analyticsOutline,
      helpCircleSharp,
      speedometerOutline,
      heartOutline,
      heart,
      checkmarkSharp,
      signals: [],
      perRequest: 20,
      requests: 0,
      noMoreSignals: false,
      loading: true,
      canvasHeight: 300,
      profits: [],
      watched: false,
      watching: false,
      ready: false,
      chartHeight: 500,
      chartReady: false,
      techAnalysisReady: false,
      lastScrollTop: 0,
      toolbarTheme: "dark",
      content: null,
    };
  },
  components: {
    IonButton,
    IonIcon,
    IonText,
    "base-layout-2": BaseLayout2,
    IonThumbnail,
    IonSkeletonText,
    IonFab,
    IonFabButton,
  },

  methods: {
    setIonContent(content) {
      //Get the page's ion-content element for scroll control
      this.content = content;
    },

    async presentAlert(data) {
      // header, message, actionText, actionHandler
      let buttons = [
        {
          text: this.lang.signal_page.explain_dialog.close,
          role: "cancel",
          cssClass: "secondary",
        },
      ];

      if (data.actionText && data.actionHandler) {
        buttons.push({
          text: data.actionText,
          handler: () => {
            data.actionHandler();
          },
        });
      }

      const alert = await alertController.create({
        cssClass: data.cssClass,
        header: data.header,
        message: data.message,
        buttons,
      });
      return alert.present();
    },

    async init() {
      let self = this;

      setTimeout(() => {
        //Set signal watched status
        this.watched = this.signal.data.watched;

        this.ready = true;

        //Initialize guide if this is first time opening a signal
        this.$nextTick(async () => {
          let guideSeen = await self.store.plugins.Storage.get({
            key: "signal_page_guide",
          });

          if (!guideSeen.value) {
            self.guide();

            self.store.plugins.Storage.set({
              key: "signal_page_guide",
              value: "seen",
            });
          }
        });
      }, 100);
    },

    contentScroll() {
      let self = this;
      //Adjust toolbar icon theme when overlapping with content div
      let contentDistanceFromTop =
        document.getElementById("content").getBoundingClientRect().top +
        window.pageXOffset -
        80;
      let backBtn = document.getElementsByClassName("signal-page-back-btn")[0];
      if (contentDistanceFromTop <= 0) {
        self.toolbarTheme = "light";
        backBtn.classList.add("light");
        backBtn.classList.remove("dark");
      } else {
        self.toolbarTheme = "dark";
        backBtn.classList.remove("light");
        backBtn.classList.add("dark");
      }
    },

    guide() {
      let self = this;

      self.guideMode(true, self);

      //Set the labels in tour client.
      window.tourClient.setOptions({
        prevLabel: self.lang.back ?? "Back",

        nextLabel: self.lang.next ?? "Next",

        finishLabel: self.lang.close ?? "Close",
      });

      //Set the steps in tour client
      let steps = [
        {
          content:
            self.lang.signal_favorite_button_description ??
            `Trading with this signal? Add it to your pins for quick access.`,

          title: self.lang.pin_this_signal ?? "Pin this signal",

          target: "#watch-signal-btn",
        },
      ];

      window.tourClient.options.steps = steps.filter((item) => {
        return item != null;
      });

      //When the tour guide is closed at any point
      window.tourClient.onAfterExit(() => {
        self.guideMode(false, self);
      });

      window.tourClient.start();
    },

    async watchSignal() {
      let self = this;

      //If we're in web app mode we can't be liking apps
      if (self.browser) {
        self.downloadTheApp("Add signals to favorites on the mobile app");
        return;
      }

      //A watch request not already running.
      if (!self.watching) {
        //REACT IN THE FRONT END
        self.watched = !self.watched;
        self.watching = true;

        //Change signal watch attribute in state.
        self.signal.data.watched = self.watched;

        //Toast to the uesr
        let options = {
          duration: 2000,
        };

        if (self.watched) {
          options.message =
            self.$filters.morphReplaceText(
              self.lang.signal_added_to_favorites,
              [["$1", self.signal.data.market]]
            ) ??
            `<strong>${self.signal.data.market}</strong> signal added to favorites`;
          options.color = "success";
        } else {
          options.message =
            self.$filters.morphReplaceText(
              self.lang.signal_removed_from_favorites,
              [["$1", self.signal.data.market]]
            ) ??
            `<strong>${self.signal.data.market}</strong> signal removed from favorites`;
          options.color = "danger";
        }
        self.showToast(options);

        //Try watching signal in server
        try {
          let resp = await window.axios({
            url: self.store.endpoint + "/toggle-watch",
            method: "post",
            data: {
              signal_id: self.signal.data.id,
              device_id: self.store.state.deviceID,
              watched: self.watched,
              sell_notifications:
                this.store.state.settings.notifications.sell_notifications,
            },
          });

          if (resp.status != 200) {
            self.networkError("Network error. Failed to pin signal");
            self.watched = !self.watched;
          } else {
            //OPerations to perform after Signal successfully watched
            let event = "toggle-watch-spot";

            if (self.signal.data.type != "spots") {
              event = "toggle-watch-future";
            }
            
            //Trigger spots components to add or remove signal
            self.store.actions.triggerCompAction(event, {
              signal: self.signal.data,
              watch: self.watched,
            });
          }
        } catch (e) {
          console.log(e);
        }

        self.watching = false;
      }
    },
  },

  watch: {
    async compActionTrigger() {
      let self = this;
      let action = self.store.state.compAction;

      switch (action.type) {
        case "reinitialize":
          // alert("bOUT TO REINIT");++`
          if (action.data.route == "/signal") {
            self.ready = false;
            self.$nextTick(async () => {
              await self.init();
            });
          }
          break;
      }
    },

    ready() {
      if (this.ready) {
        let self = this;

        self.$nextTick(() => {
          "FTT-USDT"
          let arr = self.signal.data.market.split("-");
          let symbol = arr[0] + arr[1];
          
          "USDTFTT"

          //READY CHART
          new window.TradingView.widget({
            autosize: true,
            symbol,
            interval: "D",
            timezone: "Etc/UTC",
            theme: "light",
            style: "1",
            locale: self.tradingViewLocale,
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            hide_side_toolbar: false,
            withdateranges: true,
            allow_symbol_change: true,
            container_id: "chart",
          });

          self.chartReady = true;

          //READY TECHNICAL ANALYSIS
          const script = document.createElement("script");
          // script.id = scriptID.value;
          script.type = "text/javascript";
          script.async = true;
          script.src =
            "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
          script.textContent = JSON.stringify({
            interval: "1m",
            width: "100%",
            isTransparent: false,
            height: "100%",
            symbol,
            showIntervalTabs: true,
            locale: self.tradingViewLocale,
            colorTheme: "light",
          });

          let techAnalysisView = document.getElementById("tech_analysis");
          techAnalysisView.appendChild(script);

          self.techAnalysisReady = true;
        });
      }
    },
  },

  computed: {
    signal() {
      return this.store.getters.signalInView();
    },

    server() {
      return this.store.server;
    },

    targets() {
      return JSON.parse(this.signal.data.targets);
    },

    median_profit() {
      if (this.profits.length > 0) {
        return this.median(this.profits);
      } else {
        return 0;
      }
    },

    profit_mode() {
      if (this.profits.length > 0) {
        return this.mode(this.profits);
      } else {
        return 0;
      }
    },

    compActionTrigger() {
      return this.store.state.compActionTrigger;
    },

    explainAnimation() {
      return this.store.state.explainAnimation;
    },

    leverage() {
      let leverageType = this.signal.data.leverage_type.toUpperCase();

      return leverageType + " " +  this.signal.data.leverage + "X"
    },
  },

  async ionViewDidEnter() {
    if (!this.store.state.appReady) {
      this.emitter.emit("appSignedIn");
    }

    this.init();
    // this.ready = false;
  },

  ionViewDidLeave() {
    this.ready = false;
    this.chartReady = false;
    this.techAnalysisReady = false;
  },

  created() {
    //Set Chart height
    let chartHeight = window.innerHeight - 280;
    this.chartHeight = chartHeight >= 500 ? chartHeight : 500;
  },

  mounted() {
    //Check for guide
  },
};
</script>


<style lang="scss">
ion-alert.signalInfoAlert {
  z-index: 20001;
  --width: 100%;
  --max-width: unset;
  padding: 16px;
  --height: 500px;
  --background: #{lighten($primary-color, 80%)};
  --color: #000;

  .alert-message {
    max-height: unset;
  }
}

#explainSignalsTable {
  border-collapse: collapse;
  color: #000;

  th {
    font-weight: bold;
  }

  td:first-child {
    vertical-align: top;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: $primary-color;
    color: #fff;
  }
}
</style>

<style scoped lang="scss">
.page-container {
  background: $primary-color;
  overflow: auto;
  padding-top: 50px;
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  // height: 247px;
  position: relative;

  .logo {
    display: flex;
    justify-content: center;

    ion-thumbnail {
      // --size: 70px;
      // background: lighten($primary-color, 80%);
      // padding: 10px;
      // --border-radius: 30px;
      // box-shadow: 0 9px 60px -30px #000;

      background: lighten($primary-color, 80%);
      --size: 80px;
      padding: 10px;
      --border-radius: 30px;
      box-shadow: 0 9px 60px -30px #000;
    }
  }

  .item-1 {
    text-align: center;
    // color: #ddd;
    color: lighten($primary-color, 80%);

    ion-text.market {
      font-size: calc(20px + $extra-font-size);
      font-weight: 900;

      p {
        margin-bottom: 5px;
        margin-top: 10px;

        .position {
          &.success {
            color: $success-color;
          }

          &.danger {
            color: $danger-color;
          }
        }
      }
    }

    ion-text.other {
      font-size: calc(14px + $extra-font-size);
      p {
        margin-top: 0;
        margin-bottom: 0;

        .risk {
          // color: #eee;
          color: lighten($primary-color, 80%);
          font-size: calc(16px + $extra-font-size);

          &.danger {
            color: $danger-color;
          }

          &.warning {
            color: $warning-color;
          }

          &.success {
            color: $success-color;
          }
        }

        &#price-action {
          padding-top: 10px;

          span {
            font-size: calc(12px + $extra-font-size);
            padding: 5px;
            border-radius: 5px;
            color: #fff;
            font-weight: 600;

            &.buy {
              background-color: $success-color;
            }

            &.stop {
              background-color: $danger-color;
              margin-left: 10px;
            }

            &.risk {
              border: 1px solid;
              margin-left: 10px;

              &.danger {
                border-color: #{lighten($danger-color, 10%)};
                color: #{lighten($danger-color, 10%)};
              }

              &.warning {
                border-color: #{lighten($warning-color, 10%)};
                color: #{lighten($warning-color, 10%)};
              }

              &.success {
                border-color: #{lighten($success-color, 10%)};
                color: #{lighten($success-color, 10%)};
              }
            }
          }
        }
      }
    }
  }

  .item-2 {
    display: flex;
    min-height: 30px;
    margin-top: 0;
    font-size: calc(16px + $extra-font-size);
    position: relative;
    bottom: -16px;
    width: calc(100% + 32px);
    left: -16px;
    color: lighten($primary-color, 80%);

    .side {
      flex-grow: 1;
      padding: 12px 16px;

      &.hidden {
        span {
          visibility: hidden;
        }
      }

      .item-value {
        height: 25px;
      }

      .achieved {
        font-weight: bold;
      }
    }

    .left {
      background: lighten($primary-color, 6%);
      // font-weight: 600;

      span {
        line-height: 0.5px;
      }
    }

    .right {
      background: lighten($primary-color, 11%);

      span {
        line-height: 0.5px;
      }

      .help-circle {
        position: absolute;
        right: 16px;
        top: unset;
        text-decoration: underline;
        bottom: 10px;
        display: inline;
        line-height: 1;
      }

      .bold {
        font-weight: bold;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  position: absolute;
  width: 100%;
  left: 0;
  border-radius: 0;

  /* & > div {
    margin-bottom: 20px;
    border-radius: 30px;
  } */

  #chartWrap {
    background: hsl(0, 0%, 95.3%);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;

    &.chart-ready {
      border-bottom: none;
    }

    ion-icon {
      color: hsl(0, 0%, 90.3%);
      font-size: calc(80px + $extra-font-size);
    }

    #chart {
      width: 100%;
      height: 100%;
      position: absolute;

      &.chart-ready {
        z-index: 1;
      }
    }
  }

  #tech_analysis_wrap {
    background: hsl(0, 0%, 95.3%);
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    ion-icon {
      color: hsl(0, 0%, 90.3%);
      font-size: calc(80px + $extra-font-size);
    }

    #tech_analysis {
      width: 100%;
      height: 100%;
      position: absolute;

      &.tech-ready {
        z-index: 1;
      }
    }
  }

  #timeline {
    border-radius: 0;
  }
}

ion-skeleton-text {
  border-radius: 1px;
  background: lighten($primary-color, 80%);
}

ion-fab.signal-ion-fab {
  position: fixed;
  width: fit-content;
  height: fit-content;

  ion-icon {
    color: #555;
    transition: transform 0.2s;
  }

  &.watched {
    ion-fab-button.ion-fab-button {
      --background: #{lighten($danger-color, 30%)};
    }

    ion-icon {
      color: #{darken($danger-color, 10%)};
      transform: scale(1.3);
    }
  }

  ion-fab-button.ion-fab-button {
    --background: #fefefe;
  }
}
</style>